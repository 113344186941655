import _ from 'lodash'
import {
  isOperationAllowedInContext,
  runInContext,
  waitForChangesApplied,
} from '../../privates/util'
import {resolveOption} from '../../../../utils/utils'

const isRefComponentType = (documentServices, componentRef) =>
  documentServices.components.getType(componentRef) ===
  'wysiwyg.viewer.components.RefComponent'

function removeAllOverrides(documentServices, appData, token, {componentRef}) {
  return new Promise((resolve, reject) => {
    if (!isRefComponentType(documentServices, componentRef)) {
      reject('This method remove overrides only for a refComponent.')
      return
    }

    if (
      !isOperationAllowedInContext(
        appData,
        documentServices.components.data.get(componentRef).appDefinitionId,
      )
    ) {
      reject(
        `The component with id '${componentRef.id}' doesn't belong to this app.`,
      )
      return
    }

    try {
      runInContext(appData.appDefinitionId, documentServices, () => {
        documentServices.components.refComponents.removeAllOverrides(
          componentRef,
        )
        waitForChangesApplied(documentServices, () => resolve())
      })
    } catch (ex) {
      reject(ex)
    }
  })
}

function removeOverrides(
  documentServices,
  appData,
  token,
  {componentRef, ...options},
) {
  return new Promise((resolve, reject) => {
    if (!isRefComponentType(documentServices, componentRef)) {
      reject('This method remove overrides only for a refComponent.')
      return
    }

    if (
      !isOperationAllowedInContext(
        appData,
        documentServices.components.data.get(componentRef).appDefinitionId,
      )
    ) {
      reject(
        `The component with id '${componentRef.id}' doesn't belong to this app.`,
      )
      return
    }

    try {
      runInContext(appData.appDefinitionId, documentServices, () => {
        documentServices.components.refComponents.removeOverrides(
          componentRef,
          options,
        )
        waitForChangesApplied(documentServices, () => resolve())
      })
    } catch (ex) {
      reject(ex)
    }
  })
}

function hasOverrides(documentServices, appData, token, {componentRef}) {
  return new Promise((resolve, reject) => {
    if (!isRefComponentType(documentServices, componentRef)) {
      reject('This method checks overrides only for a refComponent.')
      return
    }

    if (
      !isOperationAllowedInContext(
        appData,
        documentServices.components.data.get(componentRef).appDefinitionId,
      )
    ) {
      reject(
        `The component with id '${componentRef.id}' doesn't belong to this app.`,
      )
      return
    }

    try {
      runInContext(appData.appDefinitionId, documentServices, () =>
        documentServices.components.refComponents.hasOverridesToBeRemoved(
          componentRef,
        ),
      )
    } catch (ex) {
      reject(ex)
    }
    resolve()
  })
}

function getCollapsedRefComponents(
  documentServices,
  appData,
  token,
  {componentRef, includeInnerCollapsed},
) {
  if (!isRefComponentType(documentServices, componentRef)) {
    throw new Error(
      'This method get collapsed components only for a refComponent.',
    )
  }

  if (
    !isOperationAllowedInContext(
      appData,
      documentServices.components.data.get(componentRef).appDefinitionId,
    )
  ) {
    throw new Error(
      `The component with id '${componentRef.id}' doesn't belong to this app.`,
    )
  }

  const refHosts = [componentRef]
  if (includeInnerCollapsed) {
    const nestedChildren =
      documentServices.deprecatedOldBadPerformanceApis.components.getChildren(
        componentRef,
        true,
      )
    refHosts.push(
      ...nestedChildren.filter((compRef) =>
        isRefComponentType(documentServices, compRef),
      ),
    )
  }
  return refHosts.reduce((acc, refHost) => {
    const ghosts =
      documentServices.components.refComponents.getAllGhostRefComponentsPrimaryConnection(
        refHost,
      )
    if (ghosts) {
      Object.keys(ghosts).forEach((ghostId) => {
        acc.push({
          refHost,
          componentRef: documentServices.components.get.byId(ghostId),
          role: ghosts[ghostId]?.role,
        })
      })
    }
    return acc
  }, [])
}

function isRefComponentCollapsed(
  documentServices,
  appData,
  token,
  {componentRef},
) {
  return (
    _.get(documentServices.components.properties.get(componentRef), 'ghost') ===
    'COLLAPSED'
  )
}

function getAllAppRefComponents(
  documentServices,
  appData,
  token,
  {appDefinitionId} = {},
) {
  const allComps =
    documentServices.deprecatedOldBadPerformanceApis.components.getAllComponentsFromFull()
  const appDefId = resolveOption(
    appData,
    {appDefinitionId},
    'appDefinitionId',
    {isRequired: true},
  )
  return allComps.filter((compRef) => {
    const dataItem = documentServices.components.data.get(compRef) || {}
    return (
      dataItem.type === 'WidgetRef' && dataItem.appDefinitionId === appDefId
    )
  })
}

const getTemplateComponent = (
  documentServices,
  appData,
  token,
  {componentRef},
) =>
  documentServices.components.refComponents.getTemplateCompPointer(componentRef)

const getHostComponentRef = (
  documentServices,
  appData,
  token,
  {componentRef},
) =>
  documentServices.components.refComponents.getRefHostCompPointer(componentRef)

function collapseReferredComponent(
  documentServices,
  appData,
  token,
  {componentRef},
) {
  return new Promise((resolve, reject) => {
    if (
      !documentServices.components.refComponents.isReferredComponent(
        componentRef,
      )
    ) {
      return reject(
        `The component with id '${componentRef.id}' isn't a referred component.`,
      )
    }

    const refHost =
      documentServices.components.refComponents.getRefHostCompPointer(
        componentRef,
      )
    if (
      !isOperationAllowedInContext(
        appData,
        documentServices.components.data.get(refHost).appDefinitionId,
      )
    ) {
      return reject(
        `The component with id '${componentRef.id}' doesn't belong to this app.`,
      )
    }

    try {
      runInContext(appData.appDefinitionId, documentServices, () =>
        documentServices.components.remove(componentRef),
      )
      documentServices.waitForChangesApplied(resolve)
    } catch (ex) {
      return reject(ex)
    }
  })
}

function expandReferredComponent(
  documentServices,
  appData,
  token,
  {componentRef},
) {
  return new Promise((resolve, reject) => {
    if (
      !documentServices.components.refComponents.isReferredComponent(
        componentRef,
      )
    ) {
      return reject(
        `The component with id '${componentRef.id}' isn't a referred component.`,
      )
    }

    const refHost =
      documentServices.components.refComponents.getRefHostCompPointer(
        componentRef,
      )
    if (
      !isOperationAllowedInContext(
        appData,
        documentServices.components.data.get(refHost).appDefinitionId,
      )
    ) {
      return reject(
        `The component with id '${componentRef.id}' doesn't belong to this app.`,
      )
    }

    try {
      runInContext(appData.appDefinitionId, documentServices, () =>
        documentServices.components.refComponents.unGhostifyComponent(
          componentRef,
        ),
      )
      documentServices.waitForChangesApplied(resolve)
    } catch (ex) {
      return reject(ex)
    }
  })
}

export default {
  removeAllOverrides,
  removeOverrides,
  getAllAppRefComponents,
  isRefComponentCollapsed,
  getCollapsedRefComponents,
  collapseReferredComponent,
  getTemplateComponent,
  getHostComponentRef,
  expandReferredComponent,
  hasOverrides,
}
