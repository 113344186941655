const constants = {
  APP: {
    TYPE: {
      SECTION: 'tpaSection',
      WIDGET: 'tpaWidget',
      PLATFORM_ONLY: 'platformOnly',
    },
  },
  COMPONENT: {
    TYPE: {
      PLATFORM: 'PLATFORM',
      STUDIO: 'STUDIO',
      STUDIO_WIDGET: 'STUDIO_WIDGET',
      PAGE_OUT_OF_IFRAME: 'PAGE_OUT_OF_IFRAME',
      WIDGET_OUT_OF_IFRAME: 'WIDGET_OUT_OF_IFRAME',
      WIDGET: 'WIDGET',
      PAGE: 'PAGE',
    },
  },
}
const getAppComponents = (app) =>
  app?.appFields?.appService?.components ||
  app?.data?.components ||
  app?.components ||
  []

const isPlatformType = (app) =>
  Boolean(app?.appFields?.platform) ||
  getAppComponents(app).some(
    (comp) =>
      comp.type === constants.COMPONENT.TYPE.PLATFORM ||
      comp.compType === constants.COMPONENT.TYPE.PLATFORM,
  )

const isWidgetType = (app) =>
  Object.values(app?.widgets || {}).some(
    (widget) => !widget.appPage && !!widget.default,
  )

const isSectionType = (app) =>
  Object.values(app?.widgets || {}).some(
    (widget) => !!widget.appPage && !widget.appPage.hidden,
  )

const isBuilderType = (app) =>
  getAppComponents(app).some(
    (comp) =>
      comp.type === constants.COMPONENT.TYPE.STUDIO ||
      comp.compType === constants.COMPONENT.TYPE.STUDIO_WIDGET,
  )

const isHeadlessInstallation = (app) => {
  const components = getAppComponents(app)
  return (
    app?.appFields?.platform?.platformOnly ||
    components.find(
      (comp) => comp.type === 'PLATFORM' || comp.compType === 'PLATFORM',
    )?.data?.platformOnly
  )
}

const getAppType = function (app) {
  if (isHeadlessInstallation(app)) {
    return constants.APP.TYPE.PLATFORM_ONLY
  } else if (isSectionType(app)) {
    return constants.APP.TYPE.SECTION
  } else if (isBuilderType(app)) {
    return constants.APP.TYPE.PLATFORM_ONLY
  } else if (isWidgetType(app)) {
    return constants.APP.TYPE.WIDGET
  } else if (isPlatformType(app)) {
    return constants.APP.TYPE.PLATFORM_ONLY
  }
  return constants.APP.TYPE.WIDGET
}

export {getAppType, constants}
