import _ from 'lodash'
import {
  isOperationAllowedInContext,
  runInContext,
  normalizeConfig,
} from '../privates/util'
import {resolveOption} from '../../../utils/utils'

function addRouter(
  documentServices,
  appData,
  token,
  {prefix, config, group, appDefinitionId} = {},
) {
  return new Promise((resolve) => {
    const appDefId = resolveOption(
      appData,
      {appDefinitionId},
      'appDefinitionId',
      {isRequired: true},
    )
    const routerData = {
      appDefinitionId: appDefId,
      prefix,
      config,
    }
    if (group) {
      routerData.group = group
    }
    const routerRef = runInContext(
      appData.appDefinitionId,
      documentServices,
      () => documentServices.routers.add(routerData),
    )
    resolve(routerRef)
  })
}

function removeRouter(documentServices, appData, token, {routerRef}) {
  const routerData = documentServices.routers.get.byRef(routerRef)
  if (
    !routerData ||
    !isOperationAllowedInContext(appData, routerData.appDefinitionId)
  ) {
    return
  }
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.routers.remove(routerRef),
  )
}

function getRouter(documentServices, appData, token, {routerRef}) {
  const routerData = documentServices.routers.get.byRef(routerRef)
  if (
    !routerData ||
    !isOperationAllowedInContext(appData, routerData.appDefinitionId)
  ) {
    return
  }
  return {
    id: routerData.id,
    prefix: routerData.prefix,
    config: routerData.config,
    pages: _(routerData.pages)
      .invertBy()
      .map((pageRoles, pageId) => ({
        pageRef: documentServices.pages.getReference(pageId),
        pageRoles,
      }))
      .value(),
  }
}

function updateRouter(
  documentServices,
  appData,
  token,
  {routerRef, prefix, config},
) {
  return new Promise((resolve, reject) => {
    const routerData = documentServices.routers.get.byRef(routerRef)
    if (
      !routerData ||
      !isOperationAllowedInContext(appData, routerData.appDefinitionId)
    ) {
      reject()
    } else {
      const updatedConfig = normalizeConfig(routerData.config, config)
      runInContext(appData.appDefinitionId, documentServices, () =>
        documentServices.routers.update(routerRef, {
          prefix,
          config: updatedConfig,
        }),
      )
      resolve()
    }
  })
}

function addNewRouterPage(
  documentServices,
  appData,
  token,
  {routerRef, pageTitle, pageRoles},
) {
  return new Promise((resolve) => {
    const routerData = documentServices.routers.get.byRef(routerRef)
    if (
      !routerData ||
      !isOperationAllowedInContext(appData, routerData.appDefinitionId)
    ) {
      resolve()
    }
    const pageRef = runInContext(
      appData.appDefinitionId,
      documentServices,
      () => documentServices.routers.pages.add(routerRef, pageTitle, pageRoles),
    )
    resolve(pageRef)
  })
}

function deleteRouterPage(
  documentServices,
  appData,
  token,
  {routerRef, pageRef},
) {
  return new Promise((resolve, reject) => {
    const routerData = documentServices.routers.get.byRef(routerRef)
    if (
      !routerData ||
      !isOperationAllowedInContext(appData, routerData.appDefinitionId)
    ) {
      reject()
    } else {
      runInContext(appData.appDefinitionId, documentServices, () =>
        documentServices.pages.remove(pageRef.id),
      )
      resolve()
    }
  })
}

function connectPageToRouter(
  documentServices,
  appData,
  token,
  {routerRef, pageRef, pageRoles, slug},
) {
  const routerData = documentServices.routers.get.byRef(routerRef)
  if (
    !routerData ||
    !isOperationAllowedInContext(appData, routerData.appDefinitionId)
  ) {
    return
  }

  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.routers.pages.connect(
      routerRef,
      pageRef,
      pageRoles,
      slug ? {innerRoute: slug} : undefined,
    ),
  )
}

function disconnectPageFromRouter(
  documentServices,
  appData,
  token,
  {routerRef, pageRef},
) {
  const routerData = documentServices.routers.get.byRef(routerRef)
  if (
    !routerData ||
    !isOperationAllowedInContext(appData, routerData.appDefinitionId)
  ) {
    return
  }
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.routers.pages.disconnect(routerRef, pageRef),
  )
}

function movePageToNewRouter(
  documentServices,
  appData,
  token,
  {fromRouterRef, toRouterRef, pageRef},
) {
  const fromRouterData = documentServices.routers.get.byRef(fromRouterRef)
  const toRouterData = documentServices.routers.get.byRef(toRouterRef)
  if (
    !fromRouterData ||
    !toRouterData ||
    !isOperationAllowedInContext(appData, toRouterData.appDefinitionId) ||
    !isOperationAllowedInContext(appData, fromRouterData.appDefinitionId)
  ) {
    return
  }
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.routers.pages.move(pageRef, fromRouterRef, toRouterRef),
  )
}

function getRouterByPage(documentServices, appData, token, {pageRef}) {
  const routerRef = documentServices.routers.getRouterRef.byPage(pageRef)
  if (!routerRef) {
    return routerRef
  }
  const routerData = documentServices.routers.get.byRef(routerRef)
  if (
    !routerData ||
    !isOperationAllowedInContext(appData, routerData.appDefinitionId)
  ) {
    return
  }
  return routerRef
}

function getRouterByApp(
  documentServices,
  appData,
  token,
  {appDefinitionId} = {},
) {
  const appDefId = resolveOption(
    appData,
    {appDefinitionId},
    'appDefinitionId',
    {isRequired: true},
  )
  return _.map(documentServices.routers.get.byApp(appDefId), ({prefix}) => {
    const routerRef = getRouterByPrefix(documentServices, appData, token, {
      prefix,
    })
    return getRouter(documentServices, appData, token, {routerRef})
  })
}

function getRouterByPrefix(documentServices, appData, token, {prefix}) {
  const routerRef = documentServices.routers.getRouterRef.byPrefix(prefix)
  if (!routerRef) {
    return routerRef
  }
  const routerData = documentServices.routers.get.byRef(routerRef)
  if (
    !routerData ||
    !isOperationAllowedInContext(appData, routerData.appDefinitionId)
  ) {
    return
  }
  return routerRef
}

function listConnectablePages(documentServices) {
  return documentServices.routers.pages.listConnectablePages()
}

function isValidPrefix(
  documentServices,
  appData,
  token,
  {prefix, appDefinitionId},
) {
  const appDefId = resolveOption(
    appData,
    {appDefinitionId},
    'appDefinitionId',
    {
      isRequired: true,
    },
  )
  return documentServices.routers.isValidPrefixByAppDefId(appDefId, prefix)
}

function getCurrentDynamicRouting(documentServices) {
  const currentRoute = documentServices.routers.getCurrentInnerRoute()
  if (currentRoute.isDynamic) {
    const routerData = documentServices.routers.get.byId(currentRoute.routerId)
    currentRoute.prefix = routerData.prefix
    delete currentRoute.isDynamic
    return currentRoute
  }
  return null
}

export default {
  add: addRouter,
  remove: removeRouter,
  get: getRouter,
  getAll: getRouterByApp,
  update: updateRouter,
  getByPage: getRouterByPage,
  getByPrefix: getRouterByPrefix,
  isValidPrefix,
  getCurrentDynamicRouting,
  pages: {
    add: addNewRouterPage,
    delete: deleteRouterPage,
    connect: connectPageToRouter,
    disconnect: disconnectPageFromRouter,
    move: movePageToNewRouter,
    listConnectablePages,
  },
}
