import {waitForChangesApplied} from '../privates/util'

function add(documentServices, appData, token, {label, params}) {
  return new Promise((resolve) =>
    waitForChangesApplied(documentServices, () => {
      documentServices.history.add(label, {__appParams: params})
      resolve()
    }),
  )
}

export default {
  add,
}
