import _ from 'lodash'
import {promiseApplied, runInContext} from '../privates/util'

function navigateTo(
  documentServices,
  appData,
  token,
  {pageRef, pageLink},
  returnValue,
) {
  const pageId = _.get(pageRef, 'id')
  const pageIdOrPageLink = pageId || pageLink
  if (pageIdOrPageLink) {
    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.pages.navigateTo(pageIdOrPageLink),
    )
    return promiseApplied(documentServices, returnValue)
  }
}

export {navigateTo}
