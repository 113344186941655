import {defaultSlotName} from './consts'
import {
  provisionPluginAppIfNeeded,
  createWidgetPluginComponentDefinition,
} from './utils'
export default (documentServicesAPI) => {
  function getWidgetHostRef(compRef) {
    return documentServicesAPI.components.refComponents.isReferredComponent(
      compRef,
    )
      ? documentServicesAPI.components.refComponents.getRootRefHostCompPointer(
          compRef,
        )
      : compRef
  }

  function getPlacement(slot) {
    const hostRef = getWidgetHostRef(slot.compRef)
    const {widgetId, appDefinitionId} =
      documentServicesAPI.components.data.get(hostRef)
    return {
      appDefinitionId,
      widgetId,
      slotId: slot.role,
    }
  }

  function extractSlots(slots) {
    return slots.map(({compRef, role, pluginInfo}) => {
      return {
        role,
        pluginInfo,
        compRef,
      }
    })
  }

  function getWidgetSlots(widgetCompRef) {
    const slots = extractSlots(
      documentServicesAPI.components.slots.getWidgetSlots(widgetCompRef),
    )
    return slots.map((slot) => ({
      ...slot,
      placement: getPlacement(slot),
    }))
  }

  function uninstallWidgetPlugin(slotComponentRef) {
    documentServicesAPI.components.slots.remove(
      slotComponentRef,
      defaultSlotName,
    )
  }

  function getWidgetSlot(slotCompRef) {
    const hostRef = getWidgetHostRef(slotCompRef)
    const slots = getWidgetSlots(hostRef)
    const slot = slots.find((s) => s.compRef.id === slotCompRef.id)
    return slot
  }

  async function installWidgetPlugin(slotComponentRef, widgetPointer) {
    const {applicationId} = await provisionPluginAppIfNeeded(
      documentServicesAPI,
      widgetPointer,
    )

    const widgetComponent = await createWidgetPluginComponentDefinition(
      documentServicesAPI,
      widgetPointer,
      applicationId,
    )

    const compRef = documentServicesAPI.components.slots.populate(
      slotComponentRef,
      defaultSlotName,
      widgetComponent,
    )
    return compRef
  }

  return {
    getWidgetSlots,
    uninstallWidgetPlugin,
    installWidgetPlugin,
    getWidgetSlot,
  }
}
