import {runInContext} from '../privates/util'

function getResponsiveLayout(documentServices, appData, token, {componentRef}) {
  return documentServices.responsiveLayout.get(componentRef)
}

function updateResponsiveLayout(
  documentServices,
  appData,
  token,
  {componentRef, responsiveLayout},
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.responsiveLayout.update(componentRef, responsiveLayout),
  )
}

export default {
  get: getResponsiveLayout,
  update: updateResponsiveLayout,
}
