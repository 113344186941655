import {navigateTo} from './pageNavigation'
import _ from 'lodash'
import {runInContext, waitForChangesApplied} from '../privates/util'
import {resolveOption} from '../../../utils/utils'

const ACTION_NAMES = {
  LOAD: 'load',
}

const BEHAVIOR_NAMES = {
  OPEN_POPUP: 'openPopup',
}

const addPopup = (
  documentServices,
  appData,
  token,
  {title, definition, shouldNavigateToPage = true},
) => {
  const popupRef = runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pages.popupPages.add(title, definition),
  )
  return shouldNavigateToPage
    ? navigateTo(
        documentServices,
        appData,
        token,
        {pageRef: popupRef},
        popupRef,
      )
    : popupRef
}

const addConnectedPopup = (
  documentServices,
  appData,
  token,
  {
    title,
    definition,
    controllerType,
    popupRole,
    shouldNavigateToPage = true,
    appDefinitionId,
  },
) =>
  new Promise((resolve) => {
    runInContext(appData.appDefinitionId, documentServices, () => {
      const pageRef = documentServices.pages.popupPages.add(title, definition)
      const appDefId = resolveOption(
        appData,
        {appDefinitionId},
        'appDefinitionId',
        {isRequired: true},
      )
      const controllerStructure = _.defaultsDeep(
        {
          data: {
            controllerType,
            name: controllerType,
            applicationId: appDefId,
          },
        },
        documentServices.components.buildDefaultComponentStructure(
          'platform.components.AppController',
        ),
      )
      const controllerRef = documentServices.components.add(
        pageRef,
        controllerStructure,
      )
      waitForChangesApplied(documentServices, () => {
        const popupChildrenRefs =
          documentServices.deprecatedOldBadPerformanceApis.components.getChildren(
            pageRef,
          )
        documentServices.platform.controllers.connections.connect(
          popupChildrenRefs[0],
          controllerRef,
          popupRole,
          {},
          true,
        )
        resolve(
          shouldNavigateToPage
            ? navigateTo(documentServices, appData, token, {pageRef}, pageRef)
            : pageRef,
        )
      })
    })
  })

const open = (documentServices, appData, token, {popupRef}) => {
  const popupId = _.get(popupRef, 'id')
  if (popupId) {
    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.pages.popupPages.open(popupId),
    )
  }
}

const close = (documentServices, appData) => {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pages.popupPages.close(),
  )
}

const isPopupOpened = (documentServices) =>
  documentServices.pages.popupPages.isPopupOpened()

const getApplicationPopups = (
  documentServices,
  appData,
  token,
  {appDefinitionId, includeUnmanaged} = {},
) => {
  const appDefId = resolveOption(
    appData,
    {appDefinitionId},
    'appDefinitionId',
    {isRequired: false},
  )
  return appDefId
    ? documentServices.pages.popupPages
        .getDataList()
        .filter(
          ({managingAppDefId, appDefinitionId}) =>
            managingAppDefId === appDefId ||
            (includeUnmanaged && appDefinitionId === appDefId),
        )
    : []
}

function getActions(documentServices, behavior) {
  switch (behavior.name) {
    case BEHAVIOR_NAMES.OPEN_POPUP:
      return documentServices.actions.getDefinition(ACTION_NAMES.LOAD)
    default:
      throw new Error(`Behavior is not supported: ${behavior.name}`)
  }
}

function getBehavior(documentServices, behavior) {
  switch (behavior.name) {
    case BEHAVIOR_NAMES.OPEN_POPUP:
      return {
        ...documentServices.behaviors.getDefinition(BEHAVIOR_NAMES.OPEN_POPUP),
        params: behavior.params,
      }
    default:
      throw new Error(`Behavior is not supported: ${behavior.name}`)
  }
}

const updateBehavior = (documentServices, appData, token, options) => {
  const action = getActions(documentServices, options.behavior)
  const behavior = getBehavior(documentServices, options.behavior)

  documentServices.pages
    .getPageIdList(true)
    .map((pageId) => documentServices.components.get.byId(pageId))
    .filter((pageRef) => {
      const pageBehaviors = documentServices.behaviors.get(pageRef)

      return pageBehaviors.some(
        ({behavior}) => behavior?.targetId === options.popupRef.id,
      )
    })
    .forEach((pageRef) =>
      documentServices.behaviors.update(
        pageRef,
        action,
        options.popupRef,
        behavior,
      ),
    )
}

export {
  addPopup,
  addConnectedPopup,
  open,
  close,
  isPopupOpened,
  getApplicationPopups,
  updateBehavior,
}
