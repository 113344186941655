function getAllColors(documentServices) {
  return documentServices.theme.colors.getAll()
}

function getAllStyles(documentServices) {
  return documentServices.theme.styles.getAll()
}

function getMap(documentServices) {
  return documentServices.theme.fonts.getMap()
}

export default {
  styles: {
    getAll: getAllStyles,
  },
  colors: {
    getAll: getAllColors,
  },
  fonts: {
    getMap,
  },
}
