import {virtualSlotPlaceholderSeparator} from './consts'
import {
  provisionPluginAppIfNeeded,
  createWidgetPluginComponentDefinition,
} from './utils'

export default (documentServicesAPI) => {
  function getPluginMarketData(widgetPointer) {
    const pluginMarketDataComponent = documentServicesAPI.tpa.app
      .getDataByAppDefId(widgetPointer.appDefinitionId)
      ?.components.find(
        (component) =>
          component?.data?.referenceComponentId === widgetPointer.widgetId,
      )
    return pluginMarketDataComponent?.data
  }

  function getPluginInfo(slotsComponentData) {
    if (!slotsComponentData) {
      return undefined
    }

    return {
      ...getPluginMarketData({
        appDefinitionId: slotsComponentData.appDefinitionId,
        widgetId: slotsComponentData.widgetId,
      })?.marketData,
      widgetId: slotsComponentData.widgetId,
      appDefinitionId: slotsComponentData.appDefinitionId,
    }
  }

  function getSlotsFromAppManifest(widgetCompRef) {
    const {appDefinitionId, widgetId} =
      documentServicesAPI.components.data.get(widgetCompRef)

    if (!appDefinitionId) {
      return []
    }

    const appManifest =
      documentServicesAPI.platform.getAppManifest(appDefinitionId)

    if (!appManifest) {
      return []
    }

    const slotsMap =
      appManifest.controllersStageData?.[widgetId]?.default?.slots ?? {}

    return Object.entries(slotsMap).map(([slotName, slotConfig]) => ({
      name: slotName,
      ...slotConfig,
    }))
  }

  function getVirtualSlotsPlaceholderCompRef(widgetCompRef, slotName) {
    return {
      id: `${widgetCompRef.id}${virtualSlotPlaceholderSeparator}${slotName}`,
      type: widgetCompRef.type,
    }
  }

  function getWidgetSlotData(hostRef, slot) {
    const slotId = slot.name
    const {widgetId, appDefinitionId} =
      documentServicesAPI.components.data.get(hostRef)
    const slotComponentData = documentServicesAPI.components.data.get(
      documentServicesAPI.components.slots.getSlotsData(hostRef)[slotId],
    )
    return {
      compRef: getVirtualSlotsPlaceholderCompRef(hostRef, slotId),
      role: slotId,
      pluginInfo: getPluginInfo(slotComponentData),
      placement: {
        appDefinitionId,
        widgetId,
        slotId,
      },
    }
  }

  function getWidgetSlots(widgetCompRef) {
    const slots = getSlotsFromAppManifest(widgetCompRef)
    return slots.map((slot) => getWidgetSlotData(widgetCompRef, slot))
  }

  function getVirtualSlotPlaceholderData(compRef) {
    const [hostId, slotName] = compRef.id.split(virtualSlotPlaceholderSeparator)

    return {
      widgetHostRef: {
        id: hostId,
        type: compRef.type,
      },
      slotName,
    }
  }

  function uninstallWidgetPlugin(virtualSlotPlaceholderRef) {
    const {widgetHostRef, slotName} = getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    )

    documentServicesAPI.components.slots.remove(widgetHostRef, slotName)
  }

  function getWidgetSlot(virtualSlotsPlaceholderRef) {
    const {widgetHostRef, slotName} = getVirtualSlotPlaceholderData(
      virtualSlotsPlaceholderRef,
    )
    const slot = {displayName: slotName, name: slotName}
    return getWidgetSlotData(widgetHostRef, slot)
  }

  async function installWidgetPlugin(virtualSlotPlaceholderRef, widgetPointer) {
    const {applicationId} = await provisionPluginAppIfNeeded(
      documentServicesAPI,
      widgetPointer,
    )

    const widgetComponent = await createWidgetPluginComponentDefinition(
      documentServicesAPI,
      widgetPointer,
      applicationId,
    )

    const {widgetHostRef, slotName} = getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    )

    const compRef = documentServicesAPI.components.slots.populate(
      widgetHostRef,
      slotName,
      widgetComponent,
    )

    return compRef
  }

  return {
    getWidgetSlots,
    getWidgetSlot,
    installWidgetPlugin,
    uninstallWidgetPlugin,
  }
}
