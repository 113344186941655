import {REGISTER_ERROR} from '../../../utils/consts'

function registerToErrorThrownForContext(documentServices, appData) {
  const apiName = REGISTER_ERROR + appData.appDefinitionId
  documentServices.platform
    .requestAPIFromWorker(apiName)
    .then(({callback}) =>
      documentServices.registerToErrorThrownForContext(
        appData.appDefinitionId,
        callback,
      ),
    )
}

function unRegisterToErrorThrownForContext(documentServices, appData) {
  const apiName = REGISTER_ERROR + appData.appDefinitionId
  documentServices.platform
    .requestAPIFromWorker(apiName)
    .then(({callback}) =>
      documentServices.unRegisterToErrorThrownForContext(
        appData.appDefinitionId,
        callback,
      ),
    )
}

export default {
  registerToErrorThrownForContext,
  unRegisterToErrorThrownForContext,
}
