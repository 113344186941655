import blocksHostApiFactory from './blocksHostApi'
import tpaHostApiFactory from './tpaHostAPI'
import {virtualSlotPlaceholderSeparator} from './consts'

export default (documentServicesAPI) => {
  const blocksHostAPI = blocksHostApiFactory(documentServicesAPI)
  const tpaHostAPI = tpaHostApiFactory(documentServicesAPI)

  function isTpaWidget(widgetCompRef) {
    return documentServicesAPI.tpa.isTpaByCompType(
      documentServicesAPI.components.getType(widgetCompRef),
    )
  }

  function resolveHostAPI(widgetCompRef) {
    return isTpaWidget(widgetCompRef) ? tpaHostAPI : blocksHostAPI
  }

  function isVirtualSlotsPlaceholder(slotsPlaceholderCompRef) {
    return slotsPlaceholderCompRef.id.includes(virtualSlotPlaceholderSeparator)
  }

  function resolveHostAPIBySlotRef(slotPlaceholderCompRef) {
    return isVirtualSlotsPlaceholder(slotPlaceholderCompRef)
      ? tpaHostAPI
      : blocksHostAPI
  }

  function getWidgetSlots(widgetCompRef) {
    return resolveHostAPI(widgetCompRef).getWidgetSlots(widgetCompRef)
  }

  function getWidgetSlot(slotCompRef) {
    return resolveHostAPIBySlotRef(slotCompRef).getWidgetSlot(slotCompRef)
  }

  async function installWidgetPlugin(slotComponentRef, widgetPointer) {
    const compRef = await resolveHostAPIBySlotRef(
      slotComponentRef,
    ).installWidgetPlugin(slotComponentRef, widgetPointer)

    return compRef
  }

  async function addWidgetPlugin(options) {
    const slotInfo = await getWidgetSlot(options.slotCompRef)
    if (slotInfo?.pluginInfo !== undefined) {
      await removeWidgetPlugin(options.slotCompRef)
    }
    return installWidgetPlugin(options.slotCompRef, options.widgetPluginPointer)
  }

  function removeWidgetPlugin(slotComponentRef) {
    resolveHostAPIBySlotRef(slotComponentRef).uninstallWidgetPlugin(
      slotComponentRef,
    )
  }

  return {
    getWidgetSlots,
    addWidgetPlugin,
    removeWidgetPlugin,
  }
}
