function isGroupsPermissionsGranted() {
  return true
}

function isCustomPermissionsGranted() {
  return true
}

export default {
  isGroupsPermissionsGranted,
  isCustomPermissionsGranted,
}
