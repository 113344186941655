import _ from 'lodash'
import {promiseApplied, runInContext} from '../privates/util'
import {resolveOption} from '../../../utils/utils'
import appStudioWidgets from './appStudioWidgets'
function install(documentServices, appData, token, installationOptions) {
  return new Promise((resolve, reject) => {
    const {
      appDefinitionId,
      originInfo,
      editorType,
      sourceTemplateId,
      isSilent,
    } = installationOptions

    if (!appDefinitionId) {
      reject(new Error('options must contain appDefinitionId'))
      return
    }

    const defaultInfo = {
      appDefinitionId: _.get(appData, 'appDefinitionId'),
    }

    const info = originInfo
      ? _.assign({}, originInfo, defaultInfo)
      : defaultInfo

    const options = {
      origin: {
        initiator: 'APP',
        type: editorType,
        info,
      },
    }

    if (sourceTemplateId) {
      options.sourceTemplateId = sourceTemplateId
    }
    if (isSilent) {
      options.isSilent = true
    }

    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.platform
        .provision(appDefinitionId, options)
        .then(resolve, reject),
    )
  })
}

function uninstall(documentServices, appData, token, {appDefinitionId} = {}) {
  return new Promise((resolve) => {
    const appDefId = resolveOption(
      appData,
      {appDefinitionId},
      'appDefinitionId',
      {isRequired: true},
    )
    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.platform.uninstall(appDefId),
    )
    documentServices.waitForChangesApplied(resolve)
  })
}

function updateSessionState(documentServices, appData, token, {stateMap}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.platform.sessionState.update(stateMap),
  )
  return promiseApplied(documentServices)
}

function refresh(
  documentServices,
  appData,
  token,
  {source, shouldFetchData, appDefinitionId} = {},
) {
  const appDefId = resolveOption(
    appData,
    {appDefinitionId},
    'appDefinitionId',
    {isRequired: true},
  )
  return documentServices.platform.livePreview.refresh({
    apps: [appDefId],
    source: source || 'EditorSDK',
    shouldFetchData,
  })
}

function registerToCustomEvents(
  documentServices,
  appData,
  token,
  {eventTypes, appDefinitionId},
) {
  const appDefId = resolveOption(
    appData,
    {appDefinitionId},
    'appDefinitionId',
    {
      isRequired: true,
    },
  )
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.platform.registerAppToCustomEventsByAppDefId(
      appDefId,
      eventTypes,
    ),
  )
}

function getAppDataByAppDefId(
  documentServices,
  appData,
  token,
  appDefinitionId,
) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.platform.getAppDataByAppDefId(appDefinitionId),
  )
}

function isApplicationInstalled(
  documentServices,
  appData,
  token,
  {appDefinitionId},
) {
  return new Promise((resolve, reject) => {
    if (!appDefinitionId) {
      reject(new Error('options must contain appDefinitionId'))
      return
    }

    runInContext(appData.appDefinitionId, documentServices, () =>
      resolve(documentServices.platform.isAppActive(appDefinitionId)),
    )
  })
}

export default {
  install,
  uninstall,
  isApplicationInstalled,
  registerToCustomEvents,
  getAppDataByAppDefId,
  appStudioWidgets,
  sessionState: {
    update: updateSessionState,
  },
  livePreview: {
    refresh,
  },
}
