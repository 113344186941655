import {resolveOption} from '../../../utils/utils'
import {runInContext} from '../privates/util'

function create(
  documentServices,
  appData,
  token,
  {groupName, appDefinitionId},
) {
  const appDefId = resolveOption(
    appData,
    {appDefinitionId},
    'appDefinitionId',
    {
      isRequired: true,
    },
  )
  const pagesGroupId = runInContext(
    appData.appDefinitionId,
    documentServices,
    () =>
      documentServices.pagesGroup.create({
        groupName,
        appDefinitionId: appDefId,
      }),
  )
  return Promise.resolve(pagesGroupId)
}

function addPageToPagesGroup(
  documentServices,
  appData,
  token,
  {pagesGroupPointer, pageId},
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pagesGroup.addPageToPagesGroup(pagesGroupPointer, pageId),
  )
}

function getById(documentServices, appData, token, {pagesGroupId}) {
  return documentServices.pagesGroup.getById(pagesGroupId)
}

function getByGroupName(documentServices, appData, token, {groupName}) {
  return documentServices.pagesGroup.getByGroupName(groupName)
}

function getAll(documentServices) {
  return documentServices.pagesGroup.getAll()
}

function remove(documentServices, appData, token, {pagesGroupPointer}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.pagesGroup.remove(pagesGroupPointer),
  )
}

export default {
  create,
  addPageToPagesGroup,
  getById,
  getByGroupName,
  getAll,
  remove,
}
