import {runInContext} from '../privates/util'

function getSkipToContent(documentServices) {
  return documentServices.accessibility.skipToContent.get()
}

function setSkipToContent(documentServices, appData, token, enabled) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.accessibility.skipToContent.set(enabled),
  )
}

function getVisualFocus(documentServices) {
  return documentServices.accessibility.visualFocus.get()
}

function setVisualFocus(documentServices, appData, token, enabled) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.accessibility.visualFocus.set(enabled),
  )
}

function getAutoDomReorder(documentServices) {
  return documentServices.accessibility.autoDomReorder.get()
}

function setAutoDomReorder(documentServices, appData, token, enable) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.accessibility.autoDomReorder.set(enable),
  )
}

export default {
  getSkipToContent,
  setSkipToContent,
  getVisualFocus,
  setVisualFocus,
  getAutoDomReorder,
  setAutoDomReorder,
}
