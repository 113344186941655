import _ from 'lodash'
import {runInContext} from '../privates/util'
import {resolveOption} from '../../../utils/utils'

const PAGES_MENU_ID = 'CUSTOM_MAIN_MENU'
// This one is used in verticalMenu.spec.js inside Classic Editor and goes to list of custom menus
const STRANGE_MENU_ID = 'CUSTOM_MENU'
const MEMBERS_MENU_ID = 'MEMBERS_SUB_MENU'
const MEMBERS_LOGIN_MENU_ID = 'MEMBERS_LOGIN_MENU'
const MEMBERS_LOGIN_ICONS_MENU_ID = 'MEMBERS_LOGIN_ICONS_MENU'

const NON_CUSTOM_MENUS_IDS = [
  MEMBERS_MENU_ID,
  MEMBERS_LOGIN_MENU_ID,
  MEMBERS_LOGIN_ICONS_MENU_ID,
  PAGES_MENU_ID,
  STRANGE_MENU_ID,
]

const MAIN_MENU_TYPE = 'MAIN_MENU'

function create(
  documentServices,
  appData,
  token,
  {menuData, customId, appDefinitionId},
) {
  const appDefId = resolveOption(
    appData,
    {appDefinitionId},
    'appDefinitionId',
    {
      isRequired: true,
    },
  )

  const menuDataWithAppId = _.assign({appDefinitionId: appDefId}, menuData)
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.menu.create(menuDataWithAppId, customId),
  )
}

function connect(documentServices, appData, token, {menuCompPointer, menuId}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.menu.connect(menuCompPointer, menuId),
  )
}

function update(documentServices, appData, token, {menuId, menuData}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.menu.update(menuId, menuData),
  )
}

function remove(documentServices, appData, token, {menuId}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.menu.remove(menuId),
  )
}

function addItem(documentServices, appData, token, {menuId, menuItem}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.menu.addItem(menuId, menuItem),
  )
}

function getById(documentServices, appData, token, {menuId}) {
  return documentServices.menu.getById(menuId)
}

function getDefaultMenuId(documentServices, appData) {
  return runInContext(appData.appDefinitionId, documentServices, async () => {
    const menus = await documentServices.menu.getAll()
    const customMenus = menus.filter(
      (menu) => !NON_CUSTOM_MENUS_IDS.includes(menu.id),
    )

    if (customMenus.length === 0) {
      return null
    }

    const mainMenu = customMenus.find(
      (menu) => menu.menuType === MAIN_MENU_TYPE,
    )
    const firstMenu = customMenus[0]

    return (mainMenu || firstMenu).id
  })
}

export default {
  create,
  connect,
  update,
  remove,
  addItem,
  getById,
  getDefaultMenuId,
}
