import {runInContext} from '../privates/util'
import {resolveOption} from '../../../utils/utils'

function changeVariation(
  documentServices,
  appData,
  token,
  {componentRef, variationId},
) {
  return new Promise((resolve) => {
    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.appStudioWidgets.changeVariation(
        componentRef,
        variationId,
        resolve,
      ),
    )
  })
}

function changePreset(
  documentServices,
  appData,
  token,
  {componentRef, stylePresetId, layoutPresetId},
) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.appStudioWidgets.presets.change(
      componentRef,
      stylePresetId,
      layoutPresetId,
    ),
  )
}

function getPreset(documentServices, appData, token, {componentRef}) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.appStudioWidgets.presets.get(componentRef),
  )
}

function getProps(documentServices, appData, token, {widgetRef}) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.appStudioWidgets.props.get(widgetRef),
  )
}

function setProps(
  documentServices,
  appData,
  token,
  {widgetRef, newProps, shouldFetchData},
) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.appStudioWidgets.props.set(widgetRef, newProps, {
      shouldFetchData,
    }),
  )
}

function addWidget(documentServices, appData, token, options) {
  const {
    appDefinitionId,
    widgetId,
    scopedPresets,
    presetIds,
    layouts, // eslint-disable-line no-unused-vars
    ...moreOptions
  } = options
  const containerRef = options.containerRef

  const REF_COMPONENT_PROPS_TYPE = 'RefComponentProperties'
  function convertDimensionsByDevice(dimensionsByDevice, layout) {
    if (!dimensionsByDevice.desktop && !dimensionsByDevice.mobile) {
      return {}
    }

    const {mobile, desktop} = dimensionsByDevice

    return {
      ...(desktop
        ? {
            layout: {
              ...layout,
              width: desktop.width.value,
              height: desktop.height.value,
            },
          }
        : {layout}),
      ...(desktop
        ? {
            props: {
              minHeight: desktop.height.value,
              type: REF_COMPONENT_PROPS_TYPE,
            },
          }
        : {}),
      ...(mobile
        ? {
            mobileHints: {
              type: 'MobileHints',
              recommendedHeight: mobile.height.value,
              recommendedWidth: mobile.width.value,
              props: {
                minHeight: mobile.height.value,
                type: REF_COMPONENT_PROPS_TYPE,
              },
            },
          }
        : {}),
    }
  }

  return new Promise((resolve, reject) => {
    const appDefId = resolveOption(
      appData,
      {appDefinitionId},
      'appDefinitionId',
      {isRequired: true},
    )
    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.appStudioWidgets.addWidget(appDefId, widgetId, {
        ...moreOptions,
        containerRef,
        presets: scopedPresets?.desktop ?? presetIds,
        ...(options.dimensionsByDevice
          ? convertDimensionsByDevice(
              options.dimensionsByDevice,
              options.layout,
            )
          : {}),
        onSuccess: async (newWidgetRef) => {
          resolve(newWidgetRef)
          return
        },
        onError: reject,
      }),
    )
  })
}

function getDesignVariables(documentServices, appData, token, {widgetRef}) {
  //this code is being overwritten in the editors and call the API with ref and scoped ref
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.appStudioWidgets.designVariables.get(widgetRef, widgetRef),
  )
}

function setDesignVariables(
  documentServices,
  appData,
  token,
  {widgetRef, newValues},
) {
  //this code is being overwritten in the editors and call the API with ref and scoped ref
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.appStudioWidgets.designVariables.set(
      widgetRef,
      widgetRef,
      newValues,
    ),
  )
}

export default {
  changeVariation,
  changePreset,
  addWidget,
  getPreset,
  props: {
    get: getProps,
    set: setProps,
  },
  designVariables: {
    get: getDesignVariables,
    set: setDesignVariables,
  },
}
