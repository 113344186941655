function getFooter(documentServices) {
  return documentServices.siteSegments.getFooter()
}

function getHeader(documentServices) {
  return documentServices.siteSegments.getHeader()
}

function getPagesContainer(documentServices) {
  return documentServices.siteSegments.getPagesContainer()
}

function getSiteStructure(documentServices) {
  return documentServices.siteSegments.getSiteStructure()
}

export default {
  getFooter,
  getHeader,
  getPagesContainer,
  getSiteStructure,
}
