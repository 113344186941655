import {isExtensionContext} from '../../../utils/utils'

const promiseApplied = (documentServices, returnValue) =>
  new Promise((resolve) => {
    documentServices.waitForChangesApplied(() => resolve(returnValue))
  })

const waitForChangesApplied = (documentServices, callback) =>
  documentServices.waitForChangesApplied(callback)

const runInContext = (appDefId, documentServices, func) =>
  documentServices.runInContext(appDefId, func)

const isOperationAllowedInContext = (appData, itemAppDefinitionId) => {
  if (isExtensionContext(appData)) {
    return true
  }

  return itemAppDefinitionId === appData.appDefinitionId
}

const normalizeConfig = (oldConfig, newConfig) => {
  if (!newConfig) {
    return newConfig
  }
  // eslint-disable-next-line no-unused-vars
  const {replacers, rules, roleVariations, ...rest} = newConfig
  const updatedConfigObj = {
    ...rest,
    ...('replacers' in oldConfig && {replacers: oldConfig.replacers}),
    ...('roleVariations' in oldConfig && {
      roleVariations: oldConfig.roleVariations,
    }),
    ...('rules' in oldConfig && {rules: oldConfig.rules}),
  }
  return updatedConfigObj
}

export {
  promiseApplied,
  runInContext,
  isOperationAllowedInContext,
  waitForChangesApplied,
  normalizeConfig,
}
