import _ from 'lodash'
import {
  promiseApplied,
  runInContext,
  waitForChangesApplied,
} from '../privates/util'
import refComponents from './refComponents/refComponents'
import {resolveOption} from '../../../utils/utils'

const LAYOUT_RESPONSIVE = 'layoutResponsive'

function addComponent(
  documentServices,
  appData,
  token,
  {componentDefinition, pageRef, customId, optionalIndex} = {},
) {
  return new Promise((resolve, reject) => {
    if (!_.has(componentDefinition, 'componentType')) {
      reject(new Error('componentDefinition must contain componentType'))
    }

    const componentStructure =
      documentServices.components.buildDefaultComponentStructure(
        componentDefinition.componentType,
      )
    _.merge(componentStructure, componentDefinition)
    const compRef = runInContext(
      appData.appDefinitionId,
      documentServices,
      () =>
        documentServices.components.add(
          pageRef,
          componentStructure,
          customId,
          optionalIndex,
        ),
    )
    waitForChangesApplied(documentServices, () => resolve(compRef))
  })
}

function addAndAdjustLayout(
  documentServices,
  appData,
  token,
  {componentDefinition, pageRef, customId, optionalIndex} = {},
) {
  return new Promise((resolve, reject) => {
    if (!_.has(componentDefinition, 'componentType')) {
      reject(new Error('componentDefinition must contain componentType'))
    }

    const componentStructure =
      documentServices.components.buildDefaultComponentStructure(
        componentDefinition.componentType,
      )
    _.merge(componentStructure, componentDefinition)
    const compRef = runInContext(
      appData.appDefinitionId,
      documentServices,
      () =>
        documentServices.components.addAndAdjustLayout(
          pageRef,
          componentStructure,
          customId,
          optionalIndex,
        ),
    )
    waitForChangesApplied(documentServices, () => resolve(compRef))
  })
}

function removeComponent(documentServices, appData, token, {componentRef}) {
  if (
    documentServices.viewMode.get() ===
    documentServices.viewMode.VIEW_MODES.MOBILE
  ) {
    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.mobile.hiddenComponents.hide(componentRef),
    )
  } else {
    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.components.remove(componentRef),
    )
  }
}

function getAllComponents(documentServices) {
  return documentServices.deprecatedOldBadPerformanceApis.components.getAllComponents()
}

function getProps(documentServices, appData, token, {componentRef}) {
  return documentServices.components.properties.get(componentRef)
}

function updateProps(documentServices, appData, token, {componentRef, props}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.properties.update(componentRef, props),
  )
}

function getPage(documentServices, appData, token, {componentRef}) {
  return documentServices.components.getPage(componentRef)
}

function getById(documentServices, appData, token, {id}) {
  return documentServices.components.get.byId(id)
}

function getData(documentServices, appData, token, {componentRef}) {
  return documentServices.components.data.get(componentRef)
}

function getAncestors(documentServices, appData, token, {componentRef}) {
  return documentServices.deprecatedOldBadPerformanceApis.components.getAncestors(
    componentRef,
  )
}

function getType(documentServices, appData, token, {componentRef}) {
  return documentServices.components.getType(componentRef)
}

function isRepeatedComponent(documentServices, appData, token, {componentRef}) {
  return documentServices.components.is.repeatedComponent(componentRef)
}

function updateData(documentServices, appData, token, {componentRef, data}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.data.update(componentRef, data),
  )
}

function updateDataInLang(
  documentServices,
  appData,
  token,
  {componentRef, data, languageCode},
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.data.updateInLang(
      componentRef,
      data,
      languageCode,
    ),
  )
}

function getStyle(documentServices, appData, token, {componentRef}) {
  return documentServices.components.style.get(componentRef)
}

function updateCustomStyle(
  documentServices,
  appData,
  token,
  {componentRef, style},
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.style.setCustom(componentRef, null, style),
  )
}

function updateFullStyle(
  documentServices,
  appData,
  token,
  {componentRef, style},
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.style.update(componentRef, style),
  )
}

function mergeStylesheets(documentServices, destination, source) {
  return documentServices.components.stylable.mergeStylesheets(
    destination,
    source,
  )
}

function updateStylableStyle(
  documentServices,
  appData,
  token,
  {componentRef, style},
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.stylable.update(componentRef, style),
  )
}

function getDesign(documentServices, appData, token, {componentRef}) {
  return documentServices.components.design.get(componentRef)
}

// there is mismatch from the ts, requires 'design' but uses 'designItem'
// use whatever received not to break API or types
function updateDesign(
  documentServices,
  appData,
  token,
  {componentRef, designItem, design, retainCharas},
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.design.update(
      componentRef,
      design || designItem,
      retainCharas,
    ),
  )
}

const newStructureCompRefs = new Set()

const isOldCompStructure = (structure) => structure?.[LAYOUT_RESPONSIVE]

/**
 * This method is wasteful and not recommended to get `layoutResponsive` for sites with new structure
 */

function getComponentDataByProps(
  documentServices,
  appData,
  token,
  {componentRefs, properties = [], appDefinitionId},
) {
  const componentRefsAsArray = _.compact(
    _.isArray(componentRefs) ? componentRefs : [componentRefs],
  )
  return _.transform(
    componentRefsAsArray,
    (result, componentRef) => {
      const resObj = {componentRef}
      const propertyResolvers = new Map([
        [
          'componentType',
          () => documentServices.components.getType(componentRef),
        ],
        [
          'props',
          () => documentServices.components.properties.get(componentRef),
        ],
        ['data', () => documentServices.components.data.get(componentRef)],
        ['layout', () => documentServices.components.layout.get(componentRef)],
        [
          'sdkType',
          () => documentServices.wixCode.getCompSdkType(componentRef),
        ],
        [
          'connections',
          () =>
            documentServices.platform.controllers.connections.get(componentRef),
        ],
        ['style', () => documentServices.components.style.get(componentRef)],
        [
          'role',
          () => {
            const connections =
              documentServices.platform.controllers.connections.get(
                componentRef,
              )
            const appDefId = resolveOption(
              appData,
              {appDefinitionId},
              'appDefinitionId',
              {
                isRequired: true,
              },
            )
            const controllerRef = _.find(connections, (connection) => {
              const component = documentServices.components.data.get(
                connection.controllerRef,
              )
              return (
                (component?.appDefinitionId || component?.applicationId) ===
                appDefId
              )
            })
            return _.get(controllerRef, 'role')
          },
        ],
      ])

      const propertiesToGetFromSerialize = []
      properties.forEach((property) => {
        if (propertyResolvers.has(property)) {
          resObj[property] = propertyResolvers.get(property)()
        } else {
          propertiesToGetFromSerialize.push(property)
        }
      })

      if (propertiesToGetFromSerialize.length) {
        const isLayoutResponsiveRequest =
          propertiesToGetFromSerialize.length === 1 &&
          propertiesToGetFromSerialize[0] === LAYOUT_RESPONSIVE

        if (
          !(
            newStructureCompRefs.has(componentRef.id) &&
            isLayoutResponsiveRequest
          )
        ) {
          // TODO: 🚩🚩🚩 serialize is bad for performance! 🚩🚩🚩
          const shouldIgnoreChildren =
            !propertiesToGetFromSerialize.includes('components')
          const serializedComponent = documentServices.components.serialize(
            componentRef,
            null,
            shouldIgnoreChildren,
          )

          if (!isOldCompStructure(serializedComponent)) {
            newStructureCompRefs.add(componentRef.id)
          }

          _.assign(
            resObj,
            _.pick(serializedComponent, propertiesToGetFromSerialize),
          )
        } else if (isLayoutResponsiveRequest) {
          // we should add layoutResponsive: undefined to keep structure as before
          resObj[LAYOUT_RESPONSIVE] = undefined
        }
      }

      result.push(resObj)
    },
    [],
  )
}

function getLayout(documentServices, appData, token, {componentRef}) {
  return documentServices.components.layout.get(componentRef)
}

function showComponentOnlyOnPagesGroup(
  documentServices,
  appData,
  token,
  {componentPointer, componentRef = componentPointer, pagesGroupPointer},
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.modes.showComponentOnlyOnPagesGroup(
      componentRef,
      pagesGroupPointer,
    ),
  )
}

function activateComponentMode(
  documentServices,
  appData,
  token,
  {componentRef, modeId},
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.modes.activateComponentMode(
      componentRef,
      modeId,
    ),
  )
}

function getModes(documentServices, appData, token, {componentRef}) {
  return documentServices.components.modes.getModes(componentRef)
}

function getRuntimeState(documentServices, appData, token, {componentRef}) {
  return documentServices.components.behaviors.getRuntimeState(componentRef)
}

function updateBehavior(
  documentServices,
  appData,
  token,
  {componentRef, behavior},
) {
  return documentServices.components.behaviors.update(componentRef, behavior)
}

function getBehaviors(documentServices, appData, token, {componentRef}) {
  return documentServices.components.behaviors.get(componentRef)
}

function removeBehavior(
  documentServices,
  appData,
  token,
  {componentRef, behaviorName, actionName},
) {
  return documentServices.components.behaviors.remove(
    componentRef,
    behaviorName,
    actionName,
  )
}

function executeRuntimeBehavior(
  documentServices,
  appData,
  token,
  {componentRef, behaviorName, behaviorParams},
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.behaviors.execute(
      componentRef,
      behaviorName,
      behaviorParams,
    ),
  )
  return promiseApplied(documentServices)
}

function applyCurrentToAllModes(
  documentServices,
  appData,
  token,
  {componentRef},
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.modes.applyCurrentToAllModes(componentRef),
  )
}

function getChildren(
  documentServices,
  appData,
  token,
  {componentRef, recursive = false, fromDocument = false},
) {
  const getChildrenFn = fromDocument
    ? documentServices.deprecatedOldBadPerformanceApis.components
        .getChildrenFromFull
    : documentServices.deprecatedOldBadPerformanceApis.components.getChildren
  return getChildrenFn(componentRef, recursive)
}

function updateLayout(
  documentServices,
  appData,
  token,
  {componentRef, layout},
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.layout.update(componentRef, layout),
  )
}

function serialize(
  documentServices,
  appData,
  token,
  {componentRef, maintainIdentifiers = false},
) {
  return documentServices.components.serialize(
    componentRef,
    null,
    false,
    maintainIdentifiers,
  )
}

function migrate(
  documentServices,
  appData,
  token,
  {componentRef, componentDefinition},
) {
  return documentServices.components.migrate(componentRef, componentDefinition)
}

function moveToIndex(documentServices, appData, token, {componentRef, index}) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.arrangement.moveToIndex(componentRef, index),
  )
}

function getIndex(documentServices, appData, token, {componentRef}) {
  if (!componentRef) {
    throw new Error('options must include componentRef property')
  }

  return documentServices.components.arrangement.getCompIndex(componentRef)
}

function isFullWidth(documentServices, appData, token, {componentRef}) {
  if (!componentRef) {
    throw new Error('options must include componentRef property')
  }

  return documentServices.components.is.fullWidth(componentRef)
}

function setFullWidth(
  documentServices,
  appData,
  token,
  {componentRef, fullWidth, margins},
) {
  if (!componentRef) {
    throw new Error('options must include componentRef property')
  }

  if (typeof fullWidth !== 'boolean') {
    throw new Error('options must include fullWidth boolean property')
  }

  return new Promise((resolve) => {
    if (fullWidth) {
      const {left, right} = margins || {}
      const dockParams = {
        // vw units must be specified, otherwise the component will not be
        // considered "full-width" (i.e. `isFullWidth` will return `false`)
        left: Object.assign({vw: 0}, left),
        right: Object.assign({vw: 0}, right),
      }
      documentServices.components.layout.setDock(componentRef, dockParams)
    } else {
      documentServices.components.layout.unDock(componentRef)
    }
    waitForChangesApplied(documentServices, resolve)
  })
}

function buildDefaultComponentStructure(
  documentServices,
  appData,
  token,
  {componentType},
) {
  return documentServices.components.buildDefaultComponentStructure(
    componentType,
  )
}

function findAllByType(documentServices, appData, token, {componentType}) {
  return documentServices.components.get.byType(componentType)
}

function getNickname(documentServices, appData, token, {componentRef}) {
  if (!componentRef) {
    throw new Error('options must include componentRef property')
  }

  return documentServices.components.code.getNickname(componentRef)
}

function setNickname(
  documentServices,
  appData,
  token,
  {componentRef, nickname},
) {
  if (!componentRef) {
    throw new Error('options must include componentRef property')
  }

  if (!nickname) {
    throw new Error('options must include nickname property')
  }

  const validationResult = documentServices.components.code.validateNickname(
    componentRef,
    nickname,
  )

  if (validationResult !== 'VALID') {
    throw new Error(validationResult)
  }

  return documentServices.components.code.setNickname(componentRef, nickname)
}

export default {
  add: addComponent,
  addAndAdjustLayout,
  remove: removeComponent,
  get: getComponentDataByProps,
  serialize,
  isRepeatedComponent,
  migrate,
  getAllComponents,
  getPage,
  getById,
  getChildren,
  getAncestors,
  getType,
  isFullWidth,
  setFullWidth,
  buildDefaultComponentStructure,
  findAllByType,
  code: {
    getNickname,
    setNickname,
  },
  behaviors: {
    getRuntimeState,
    execute: executeRuntimeBehavior,
    get: getBehaviors,
    update: updateBehavior,
    remove: removeBehavior,
  },
  properties: {
    get: getProps,
    update: updateProps,
  },
  data: {
    get: getData,
    update: updateData,
    updateInLanguage: updateDataInLang,
  },
  layout: {
    get: getLayout,
    update: updateLayout,
  },
  style: {
    get: getStyle,
    update: updateCustomStyle,
    updateFull: updateFullStyle,
  },
  stylable: {
    mergeStylesheets,
    update: updateStylableStyle,
  },
  design: {
    get: getDesign,
    update: updateDesign,
  },
  modes: {
    showComponentOnlyOnPagesGroup,
    activateComponentMode,
    applyCurrentToAllModes,
    getModes,
  },
  arrangement: {
    moveToIndex,
    getIndex,
  },
  refComponents,
}
