import _ from 'lodash'
import {runInContext} from '../privates/util'

function readFile(documentServices, appData, token, options) {
  const fs = documentServices.wixCode.fileSystem
  const fileDescriptor = fs.getVirtualDescriptor(options.path, false)

  return fs.readFile(fileDescriptor)
}

function listChildren(documentServices, appData, token, options) {
  const fs = documentServices.wixCode.fileSystem
  const pathDescriptor = fs.getVirtualDescriptor(options.path, true)

  return fs
    .getChildren(pathDescriptor)
    .then((children) => _.map(children, mapVfsDescriptors))
}

function mapVfsDescriptors(vfsItemDescriptor) {
  return {
    name: vfsItemDescriptor.name,
    fullPath: vfsItemDescriptor.location,
    isDirectory: vfsItemDescriptor.directory,
  }
}

function writeFile(documentServices, appData, token, options) {
  const fs = documentServices.wixCode.fileSystem
  const fileDescriptor = fs.getVirtualDescriptor(options.path, false)

  return runInContext(appData.appDefinitionId, documentServices, () =>
    fs.writeFile(fileDescriptor, options.content),
  )
}

function deleteFile(documentServices, appData, token, options) {
  const fs = documentServices.wixCode.fileSystem
  const fileDescriptor = fs.getVirtualDescriptor(options.path, false)

  return runInContext(appData.appDefinitionId, documentServices, () =>
    fs.deleteItem(fileDescriptor),
  )
}

function flush(documentServices, appData) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.wixCode.fileSystem.flush(),
  )
}

function getViewerInfo(documentServices) {
  return documentServices.wixCode.fileSystem.getViewerInfo()
}

export default {
  listChildren,
  readFile,
  writeFile,
  deleteFile,
  flush,
  getViewerInfo,
}
